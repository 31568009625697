// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coding-interview-js": () => import("./../../../src/pages/coding-interview.js" /* webpackChunkName: "component---src-pages-coding-interview-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-crack-coding-interviews-js": () => import("./../../../src/pages/crack-coding-interviews.js" /* webpackChunkName: "component---src-pages-crack-coding-interviews-js" */),
  "component---src-pages-crack-machine-leaning-interview-js": () => import("./../../../src/pages/crack-machine-leaning-interview.js" /* webpackChunkName: "component---src-pages-crack-machine-leaning-interview-js" */),
  "component---src-pages-crack-oops-design-interview-js": () => import("./../../../src/pages/crack-oops-design-interview.js" /* webpackChunkName: "component---src-pages-crack-oops-design-interview-js" */),
  "component---src-pages-crack-system-design-interview-js": () => import("./../../../src/pages/crack-system-design-interview.js" /* webpackChunkName: "component---src-pages-crack-system-design-interview-js" */),
  "component---src-pages-data-science-course-js": () => import("./../../../src/pages/data-science-course.js" /* webpackChunkName: "component---src-pages-data-science-course-js" */),
  "component---src-pages-data-structures-and-algorithms-course-js": () => import("./../../../src/pages/data-structures-and-algorithms-course.js" /* webpackChunkName: "component---src-pages-data-structures-and-algorithms-course-js" */),
  "component---src-pages-dsa-course-for-advanced-learners-js": () => import("./../../../src/pages/dsa-course-for-advanced-learners.js" /* webpackChunkName: "component---src-pages-dsa-course-for-advanced-learners-js" */),
  "component---src-pages-dsa-course-for-beginners-and-intermediate-learners-js": () => import("./../../../src/pages/dsa-course-for-beginners-and-intermediate-learners.js" /* webpackChunkName: "component---src-pages-dsa-course-for-beginners-and-intermediate-learners-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-machine-learning-course-js": () => import("./../../../src/pages/live-machine-learning-course.js" /* webpackChunkName: "component---src-pages-live-machine-learning-course-js" */),
  "component---src-pages-machine-learning-courses-js": () => import("./../../../src/pages/machine-learning-courses.js" /* webpackChunkName: "component---src-pages-machine-learning-courses-js" */),
  "component---src-pages-machine-learning-js": () => import("./../../../src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-oops-concepts-js": () => import("./../../../src/pages/oops-concepts.js" /* webpackChunkName: "component---src-pages-oops-concepts-js" */),
  "component---src-pages-oops-course-js": () => import("./../../../src/pages/oops-course.js" /* webpackChunkName: "component---src-pages-oops-course-js" */),
  "component---src-pages-popular-tags-js": () => import("./../../../src/pages/popular-tags.js" /* webpackChunkName: "component---src-pages-popular-tags-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ravish-kumar-js": () => import("./../../../src/pages/ravish-kumar.js" /* webpackChunkName: "component---src-pages-ravish-kumar-js" */),
  "component---src-pages-refund-and-cancellation-policy-js": () => import("./../../../src/pages/refund-and-cancellation-policy.js" /* webpackChunkName: "component---src-pages-refund-and-cancellation-policy-js" */),
  "component---src-pages-reviews-and-testimonials-js": () => import("./../../../src/pages/reviews-and-testimonials.js" /* webpackChunkName: "component---src-pages-reviews-and-testimonials-js" */),
  "component---src-pages-shubham-gautam-js": () => import("./../../../src/pages/shubham-gautam.js" /* webpackChunkName: "component---src-pages-shubham-gautam-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-system-design-courses-js": () => import("./../../../src/pages/system-design-courses.js" /* webpackChunkName: "component---src-pages-system-design-courses-js" */),
  "component---src-pages-system-design-js": () => import("./../../../src/pages/system-design.js" /* webpackChunkName: "component---src-pages-system-design-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

