module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-D8KY2S63JC"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EnjoyAlgorithms","short_name":"Enjoy Algorithms","start_url":"/","display":"standalone","icon":"src/img/pwa-icon.jpg","cache_busting_mode":"none","include_favicon":false,"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#4CAF50","showSpinner":true,"thickness":6},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
