import "./src/styles/tailwind.css"
import "prismjs/themes/prism-okaidia.css"


export const onServiceWorkerUpdateReady = () => {
     const answer = window.confirm(
       `Content has been updated. Please reload the website to view the latest version.`
     )
   
     if (answer === true) {
       window.location.reload()
     }
   }